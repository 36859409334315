/* Lato sans google fonts link */
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');
/* @import url("https://use.typekit.net/tvu4caq.css"); */

@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,700;0,800;0,900;1,400;1,500;1,700;1,800;1,900&display=swap');
/* @font-face {
  font-family: 'Playfair Display';
  src: local('PlayfairDisplay'),
    url('./fonts/PlayfairDisplay-VariableFont_wght.ttf') format("ttf"),
    url('./fonts/PlayfairDisplay-Italic-VariableFont_wght.ttf') format("ttf");
  font-variation-settings: 'wght' 375;
} */

/* @font-face {
  font-family: 'Hack';
  src: local('Hack'),
        url('./fonts/Hack-Regular.ttf') format("ttf"),
        url('./fonts/Hack-Italic.ttf') format("ttf"),
        url('./fonts/Hack-Bold.ttf') format("ttf"),
        url('./fonts/Hack-BoldItalic.ttf') format("ttf");
  font-weight: normal;
  font-style: normal;
} */

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  -webkit-font-smoothing: subpixel-antialiased;
}