@mixin TranslationAnimation {
  transition: all 0.2s ease-in-out;
}

.ArticleCard {
  display: grid;
  grid-template-areas: 
    "details"
    "image";
  column-gap: 32px;
  max-width: 800px;

  @include TranslationAnimation;
}

.ArticleCard + .ArticleCard{
  border-top: 1px solid var(--gray200);
  margin-top: 3rem;

}

.ArticleCard h3 {
  color: var(--oceanBlue400);
  display: block;
  max-width: 30ch;
  position: relative;
  margin-bottom: 0;
  margin-top: 0.5em;
  z-index: 1;
  @include TranslationAnimation;
}
.ArticleCard:hover h3,
.ArticleCard:focus h3 {
  color: var(--oceanBlue500);
  @include TranslationAnimation;
}

.ArticleCard h3::after {
  content: ' ';
  z-index: -1;
  display: block;
  position: absolute;
  height: .4em;
  width: 10%;
  opacity: 0;
  bottom: 1px;
  left: 0;
  background-color: var(--oceanBlue100);
  @include TranslationAnimation;
}
.ArticleCard a:hover h3::after,
.ArticleCard a:focus h3::after {
  opacity: 1;
  width: 100%;
  left: .125em;
  @include TranslationAnimation;
}

.ArticleCard p {
  color: var(--gray700);
  margin-top: 0.25em;
  max-width: 48ch;
}

.ArticleCard img {
  @include TranslationAnimation;
  grid-area: image;
  transform: scale(1);
}

.ArticleCard:hover img,
.ArticleCard:focus img,
.ArticleCard:active img {
  @include TranslationAnimation;
  transform: scale(1.025);
}

.ArticleCard_details {
  grid-area: details;
  margin-top: 0.5rem;

  @media screen and (min-width: 600px) {
    margin-top: 2rem;
  }
}
.ArticleCard_details a {
  display: block;
  text-decoration: none;
}

.ArticleCard .articleDetail {
  font-size: var(--textSize50);
  color: var(--gray400);
}