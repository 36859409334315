@mixin TranslationAnimation {
  transition: all 0.2s ease-in-out;
}


.projectCardWrapper {
  margin: 56px 0 0;

  h2 {
    margin-top: 0;
    margin-bottom: 1em;
    @media screen and (min-width: 600) {
      margin-bottom: 0;
    }
  }
}


.projectCard {
  display: grid;
  grid-template-areas: 
    "image"
    "details";
  grid-template-columns: 100%;
  column-gap: 32px;
  -webkit-user-select: none;  /* Chrome all / Safari all */
  -moz-user-select: none;     /* Firefox all */
  -ms-user-select: none;      /* IE 10+ */
  -o-user-select: none;
  user-select: none;
  pointer-events: none;

  @include TranslationAnimation;

  @media screen and (min-width: 600px) {
    grid-template-areas: 
      "details image";
    grid-template-columns: 40% 60%; // 40% 60%
  }

  @media screen and (min-width: 800px) {
    grid-template-columns: 320px auto;
  }
}

.projectCard + .projectCard{
  margin-top: 3em;

  @media screen and (min-width: 600px) {
    margin-top: 8em;
  }
}

.projectCard h4 {
  color: var(--oceanBlue400);
  display: inline;
  width: auto;
  position: relative;
  margin-bottom: 0;
  margin-top: 0.5em;
  z-index: 1;
  @include TranslationAnimation;
}
.projectCard:hover h4,
.projectCard:focus h4 {
  color: var(--oceanBlue500);
  @include TranslationAnimation;
}

.projectCard h4::after {
  content: ' ';
  z-index: -1;
  display: block;
  position: absolute;
  height: .4em;
  width: 10%;
  opacity: 0;
  bottom: 1px;
  left: 0;
  background-color: var(--oceanBlue100);
  @include TranslationAnimation;
}
.projectCard a:hover h4::after,
.projectCard a:focus h4::after {
  opacity: 1;
  width: 100%;
  left: .125em;
  @include TranslationAnimation;
}

.projectCard p {
  color: var(--gray700);
  margin-top: 0.25em;
  max-width: 48ch;
}

.projectCard img {
  @include TranslationAnimation;
  grid-area: image;
  max-height: calc(100vh - 72px);
  -webkit-user-select: none;  /* Chrome all / Safari all */
  -moz-user-select: none;     /* Firefox all */
  -ms-user-select: none;      /* IE 10+ */
  -o-user-select: none;
  user-select: none;
  pointer-events: none;
  // transform: scale(1);
}

// .projectCard:hover img,
// .projectCard:focus img,
// .projectCard:active img {
//   @include TranslationAnimation;
//   transform: scale(1.025);
// }

.projectCard_details {
  grid-area: details;
  margin-top: 0.5rem;

  @media screen and (min-width: 600px) {
    margin-top: 2rem;
  }
}
.projectCard_details a {
  display: block;
  text-decoration: none;
}

.projectCard_details-list {
  list-style: none;
  margin: 0 0 1em;
  padding: 0;

  li {
    font-size: var(--textSize100);
    font-family: var(--fontStack);
    color: var(--gray500);
    line-height: 1.3;
  }
}