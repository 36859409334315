

@mixin TranslationAnimation {
  transition: all 0.2s ease-in-out;
}

.navigation-sub {
  // height: 48px;
  position: fixed;
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
}

.navigation-sub .item {
  position: relative;
  text-decoration: none;
  color: var(--oceanBlue300);
  height: 40px;
  align-items: center;
  display: flex;
  font-weight: 400;
  font-family: var(--fontStack);
  font-size: var(--textSize100);
  margin-left: 16px;
  @include TranslationAnimation;
}


.navigation-sub .item:hover,
.navigation-sub .item:focus,
.navigation-sub .item:active,
.navigation-sub .item.selected {
  color: var(--oceanBlue600);
  @include TranslationAnimation;
}


.navigation-sub .item::after {
  display: block;
  position: absolute;
  width: 4px;
  height: 0;
  bottom: 0;
  opacity: 0;
  background-color: var(--oceanBlue100);
  left: -2rem;
  bottom: 50%;
  content: ' ';
  @include TranslationAnimation;
}
.navigation-sub .item--topLevel::after {
  left: -1rem;
}

.navigation-sub .item.selected::after,
.navigation-sub .item:hover::after  {
  height: 100%;
  bottom: 0;
  opacity: 1;
  @include TranslationAnimation;
}
.navigation-sub .item--topLevel.selected::after,
.navigation-sub .item--topLevel:hover::after {
  height: calc(100% - 12px);
}
.navigation-sub .item.selected::after {
  background-color: var(--oceanBlue200);
}

.navigation-sub + .Popover-wrapper {
  margin-left: 18px;
}

.navigation-sub .item--topLevel {
  padding-top: 12px;
  font-size: var(--textSize200);
  margin-left: 0;
}
