img {
  width: auto;
  max-width: 100%;
  margin-bottom: 32px;
}

img.constrained {
  align-self: center;
  width: 800px;
  max-width: 100%;
  margin: 32px 0;

  + .caption {
    margin-top: -24px;
  }
}

img.Painting {
  margin-top: 16px;
  max-width: 800px; 
  height: calc(100vh - 172px);
  object-fit: contain;
  object-position: top left;
  min-height: 350px;
}

img.circle {
  border-radius: 50%;
  max-height: 480px;
}