@mixin TrackStyles {
  cursor: default;
  height: 4px;
  transition: all 0.2s ease;
  width: 100%;
  background: var(--gray400);
  border-radius: 4px;
}

@mixin ThumbStyle {
  background: var(--gray100);
  border: 2px solid var(--gray500);
  cursor: default;
  height: 16px;
  width: 16px;
}

[type=range] {
  -webkit-appearance: none;
  background: transparent;
  margin: 12px 0;
  width: 100%;
}
[type=range]::-moz-focus-outer {
  border: 0;
}
[type=range]:focus {
  outline: 0;
}
[type=range]:focus::-webkit-slider-runnable-track {
  background: var(--oceanBlue300);
}
[type=range]:focus::-ms-fill-lower {
  background: var(--oceanBlue300);
}
[type=range]:focus::-ms-fill-upper {
  background: var(--oceanBlue300);
}
[type=range]::-webkit-slider-runnable-track {
  @include TrackStyles;
}
[type=range]::-webkit-slider-thumb {
  @include ThumbStyle;
  border-radius: 12px;
  box-sizing: border-box;
  -webkit-appearance: none;
  margin-top: -7px;
}
[type=range]:focus::-webkit-slider-thumb {
  border: 4px solid var(--oceanBlue300);
}
[type=range]:active::-webkit-slider-thumb {
  border: 4px solid var(--oceanBlue300);
}

[type=range]::-moz-range-track {
  @include TrackStyles;
  height: 4px;
}
[type=range]::-moz-range-thumb {
  @include ThumbStyle;
  border-radius: 12px;
  box-sizing: border-box;
}
[type=range]:focus::-moz-range-thumb {
  border: 4px solid var(--oceanBlue300);
}
[type=range]:active::-moz-range-thumb {
  border: 4px solid var(--oceanBlue300);
}
[type=range]::-ms-track {
  @include TrackStyles;

  background: transparent;
  border-color: transparent;
  border-width: 12px 0;
  color: transparent;
}
[type=range]::-ms-fill-lower {
  // box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2), 0 0 1px rgba(13, 13, 13, 0.2);
  background: var(--gray400);
  border: 1px solid var(--gray500);
  border-radius: 8px;
}
[type=range]::-ms-fill-upper {
  // box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2), 0 0 1px rgba(13, 13, 13, 0.2);
  background: var(--gray400);
  border: 1px solid var(--gray500);
  border-radius: 8px;
}
[type=range]::-ms-thumb {
  @include ThumbStyle;
  border-radius: 12px;
  box-sizing: border-box;
  margin-top: 0;
}
[type=range]:focus::-ms-thumb {
  border: 4px solid var(--oceanBlue300);
}
[type=range]:active::-ms-thumb {
  border: 4px solid var(--oceanBlue300);
}

[type=range]:disabled::-webkit-slider-thumb, [type=range]:disabled::-moz-range-thumb, [type=range]:disabled::-ms-thumb, [type=range]:disabled::-webkit-slider-runnable-track, [type=range]:disabled::-ms-fill-lower, [type=range]:disabled::-ms-fill-upper {
  cursor: not-allowed;
}