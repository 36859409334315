
@mixin TranslationAnimation {
  transition: all 0.3s ease-in-out;
}

.menu {
  align-self: flex-start;
  width: var(--menuWidth);
  position: fixed;
  height: 100vh;
  padding: 32px 32px 0 32px;
  background-color: var(--background);
  // left: 0;
  left: -336px; // closed
  top: 0;
  display: block;
  // width: 222px;
  z-index: 20;
  opacity: 0;
  @include TranslationAnimation;

  @media screen and (min-width: 600px) {
    padding: 52px 32px 0 32px;
  }
}

#overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  display: none;
  opacity: 0;
  @include TranslationAnimation;
}

.menuWrapper.open {
  #overlay {
    opacity: 100;
    display: block;
    @include TranslationAnimation;
  }
  .menu {
    left: 0;
    opacity: 100;
    @include TranslationAnimation;
  }
} 
