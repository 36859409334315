
@mixin TranslationAnimation {
  transition: all 0.2s ease-in-out;
}

body {
  font-size: 112.5%; /* 18px */
}

.App {
  text-align: start;
  margin:0;
  padding:0;
  font-family: 'Lato', sans-serif;
  background-color: var(--background);
  color: var(--gray800);
  font-size: var(--textSize100);
  line-height: 1.5;
  overflow-x: clip;
  overflow-y: auto;
  // height: 100vh;
  // position: relative;
}


.App-content > div:not(.menu) {
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;

}

.App-content,
.App-header-content {
  padding: 0 32px;
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
}

.App-content {
  // position: relative;
  padding-top: 16px;
  display: grid;
  // grid-template-columns: 240px auto;

  @media screen and (min-width: 600px) {
    padding-top: 16px;
  }
}

.App-header-content--right {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  // gap: 18px;
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0.25em;
  margin-top: 1.25em;
  line-height: 1.2;
  position: relative;
  -webkit-font-smoothing: subpixel-antialiased;
}

h1, h2, h3, h4 {
  font-family: var(--fontSerifStack);
  font-weight: 700;
  -webkit-font-smoothing: subpixel-antialiased;
  color: var(--gray800);
  line-height: 1.2;
}

h1:first-of-type {
  margin-top: 0.5em;
}

h5, h6 {
  font-weight: 700;
  -webkit-font-smoothing: subpixel-antialiased;
  line-height: 1.2;
}

h1 {
  font-size: var(--textSize600);
  z-index:1;
  max-width: max-content;
} 


h2 {
  font-size: var(--textSize500);

}
h3 {
  font-size: var(--textSize400);
}
h4 {
  font-size: var(--textSize300);
  margin-bottom: 0;
}
h5 {
  font-size: var(--textSize200);
}

hgroup {
  h1 + h2,
  h1 + h3,
  h1 + h4,
  h2 + h3,
  h2 + h4,
  h2 + h5 {
    margin-top: 0;
  }
}

p {
  -webkit-font-smoothing: subpixel-antialiased;
  max-width: 60ch;
  margin-block-start: 0;
  margin-block-end: 0.75em;
}

p + p {
  margin-block-start: 0.5em;
}

h5 + p,
h5 + ul {
  margin-top: 0;
}

code {
  font-family: 'Hack', Menlo, Monaco, monospace;
  font-size: 0.825em;
  display: inline;
  background-color: var(--gray200);
  border: 1px solid var(--gray300);
  color: var(--burntOrange400);
  border-radius: 4px;
  padding: 0 4px;
  margin: 0 2px;
}

.body1 {
  font-family: 'Lato', sans-serif;
  font-size: var(--textSize500);
}
.body2 {
  font-family: 'Lato', sans-serif;
  font-size: var(--textSize400);
}
.body3 {
  font-family: 'Lato', sans-serif;
  font-size: var(--textSize300);
}
.body4 {
  font-family: var(--fontSerifStack);
  font-size: var(--textSize200);
}
.body5,
p {
  font-family: 'Lato', sans-serif;
  font-size: var(--textSize100);
}


.logo {
    display: inline-flex;
}

.logo svg {
  height: 48px;
  fill: var(--gray900);

  path { 
    fill: var(--gray900);
  }

  @media screen and (min-width: 600px) {
    height: 90px;
  }
}

.App-header {
  // background-color: var(--gray100);
  // box-shadow: 0 20px 20px 10px var(--gray100);
  display:flex;
  height: 110px;
  font-size: 1rem;
  color: var(--gray700);
  position: relative;
  width: 100vw;
  z-index:10;
  margin-top: 0;
  align-items: center;

  @media screen and (min-width: 600px) {
    height: 140px;
  }
}

.App-header-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;

  @media screen and (min-width: 600px) {
    height: 160px;
  }
}

.App-link {
  color: #61dafb;
}

.intro {
  height: 70vh;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.intro_Text {
  font-size: var(--textSize200);
  margin-bottom: 0;
}


.intro_Heading {
  margin-bottom: 0;
  margin-top: 0;
  letter-spacing: -0.035em;
  font-size: var(--textSize800);
}

.intro_Heading::after {
  display: none;
}

.body--callout {
  color: var(--gray600);
  max-width: 40ch;
  font-family: var(--fontSerifStack);
  font-size: var(--textSize200);

  + .button {
    margin-top: 2em;
  }
} 

.cta {
  background: linear-gradient(to right, var(--oceanBlue400), var(--oceanBlue100));
  padding: 56px;
  color: var(--gray50);
  font-weight: 700;
}

a {
  text-underline-offset: 2px;
  color: var(--oceanBlue300)
}
a:visited {
  color: var(--burntOrange300);
}

.colorRamp {
  display: block;
  height: var(--swatchSize);
  width: 100%;
  border-radius: 8px;
  box-shadow: inset 1px 1px 0 0 rgba(0, 0, 0, 0.1), inset -1px -1px 0 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 8px;
  @media screen and (min-width: 600px) {
    margin-bottom: 16px;
  }
}

.colorSwatch {
  height: var(--swatchSize);
  width: calc(var(--swatchSize) * 1.5);
  border-radius: 8px;
  box-shadow: inset 1px 1px 0 0 rgba(0, 0, 0, 0.1), inset -1px -1px 0 0 rgba(0, 0, 0, 0.1);
  display: inline-flex;
  // flex-direction: column;
  // justify-content:space-between;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 8px;
  @media screen and (min-width: 600px) {
    margin-right: 16px;
  }  
  margin-bottom: 8px;
  @media screen and (min-width: 600px) {
    margin-bottom: 16px;
  }

  .swatchContrastValue {
    display: flex;
    flex-direction: row;
    -webkit-font-smoothing: subpixel-antialiased;

    span {
      display: inline-flex;
      padding: 2px 0 0 6px;
      font-size: 0.75rem;
      opacity: 0.65;
    }
    span + span {
      padding: 2px 0 0 0;
    }
  }

  .swatchColorValue {
    padding: 2px 0 0 6px;
    display: block;
    font-size: 0.75rem;
    -webkit-font-smoothing: subpixel-antialiased;

  }
}

// .colorName {
//   font-size: 0.75rem;
// }

span.colorContrast {
  opacity: 1;
  font-size: 0.75rem;
}

.colorGroup {
  margin-bottom: 32px;
}

fieldset {
  border: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  div {
    display: flex;
    flex-direction: column;
  }

  div + div {
    margin-top: 8px;
  }
}

.ArtDetails {
  font-size: var(--textSize50);
  color: var(--gray500);
  font-style: italic;
  margin-top: 0;
  margin-bottom: 0;
}

iframe {
  align-self: center;
}

.heroImage {
  object-fit: cover;
  height: 60vh;
  width: 100vw;
  overflow: auto;
}

.splitView {
  display: grid;
  grid-template-rows: auto;
  img.circle {
    justify-self: center;
  }

  @media screen and (min-width: 800px) {
    grid-template-columns: 50% 50%;    
    img.circle {
      justify-self: flex-start;
      margin-top: 2.5em;
    }
  }
}

.splitView--centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  img.circle {
    max-height: 300px;
  }
}

blockquote {
  margin: 24px 0 24px;
  padding: 12px;
  border-left: 4px solid var(--oceanBlue100);
  font-size: var(--textSize200);
  color: var(--gray800);
  font-family: var(--fontSerifStack);
  font-weight: 500;
  max-width: 60ch;

  cite {
    display: block;
    color: var(--gray500);
    font-size: var(--textSize100);
    font-family: var(--fontStack);
    padding-top: 0.5rem;

    &::before {
      display: inline-block;
      content: '\2014';
      margin-right: 0.25rem;
    }
  }
}