
:root {
  --fontSerif: 'Playfair Display';
  --fontSerifStack: var(--fontSerif), Georgia, serif;
  --fontStack: 'Lato', sans-serif;

  --swatchSize: 48px;
  @media screen and (min-width: 600px) {
    --swatchSize: 64px;
  }

  --textSize100: 0.875rem;
  @media screen and (min-width: 600px) {
    --textSize100: 1rem;
  }
  --typeScale: 1.2;
    @media screen and (min-width: 600px) {
      --typeScale: 1.25;
    }

  --textSize100: 1rem;
  --textSize50: calc(var(--textSize100) / var(--typeScale));
  --textSize200: calc(var(--textSize100) * var(--typeScale));
  --textSize300: calc(var(--textSize200) * var(--typeScale));
  --textSize400: calc(var(--textSize300) * var(--typeScale));
  --textSize500: calc(var(--textSize400) * var(--typeScale));
  --textSize600: calc(var(--textSize500) * var(--typeScale));
  --textSize700: calc(var(--textSize600) * var(--typeScale));
  --textSize800: calc(var(--textSize700) * var(--typeScale));

  --menuWidth: 320px;

}
/* Providing defaults... */
@media (prefers-color-scheme: dark) {
  html {
    --background: #1d1d1d;
    background-color: var(--background);
    --dropShadow: rgba(0, 0, 0, 0.25);
  }
  .App,
  .go2197912528 > div {
    --background: #1d1d1d;
    --gray50: #111111;
    --gray100: #1d1d1d;
    --gray200: #2c2c2c;
    --gray300: #474747;
    --gray400: #686868;
    --gray500: #848484;
    --gray600: #9b9b9b;
    --gray700: #c8c8c8;
    --gray800: #f2f2f2;
    --oceanBlue100: #0d2f3d;
    --oceanBlue200: #21717f;
    --oceanBlue300: #3d9093;
    --oceanBlue400: #66ada7;
    --oceanBlue500: #9dccc0;
    --oceanBlue600: #d1e7de;
    --burntOrange100: #5f0102;
    --burntOrange200: #ae4810;
    --burntOrange300: #cb6a0e;
    --burntOrange400: #e28e17;
    --burntOrange500: #f6b84a;
    --burntOrange600: #ffdea0;
  }
}
@media (prefers-color-scheme: light) {
  html {
    --background: #ffffff;
    background-color: var(--background);
    --dropShadow: rgba(0, 0, 0, 0.15);
  }
  .App,
  .go2197912528 > div {
    --background: #ffffff;
    --gray50: #ffffff;
    --gray100: #ffffff;
    --gray200: #eaeaea;
    --gray300: #c1c1c1;
    --gray400: #959595;
    --gray500: #767676;
    --gray600: #636363;
    --gray700: #424242;
    --gray800: #262626;
    --oceanBlue100: #deeee5;
    --oceanBlue200: #54a19e;
    --oceanBlue300: #2e8289;
    --oceanBlue400: #1a6677;
    --oceanBlue500: #104b5f;
    --oceanBlue600: #0d3545;
    --burntOrange100: #ffe8ba;
    --burntOrange200: #da7e0f;
    --burntOrange300: #be5a0f;
    --burntOrange400: #a33d0e;
    --burntOrange500: #871d04;
    --burntOrange600: #690302;
  }
}

@mixin TranslationAnimation {
  transition: all 0.2s ease-in-out;
}
